import { FormInstance, RuleObject } from "antd/es/form";
import { NamePath, StoreValue } from "antd/es/form/interface";
import {
  emailRegex,
  passwordRegex,
  deaRegex,
  npiRegex,
} from "../content/regex";
import useMakeApiRequest from "../../api/useMakeApiRequest";
import { CountryData } from "react-phone-input-2";

export const emailPattenValidation = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (emailRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("Invalid Email Format!");
    },
  };
};

export const validateMaxLength = (fieldName: string, maxLength: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (value.length < maxLength) {
        return Promise.reject(
          `${fieldName} should be ${maxLength} characters long`
        );
      }
      return Promise.resolve();
    },
  };
};

export const validateExactLength = (fieldName: string, exactLength: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (value.length !== exactLength) {
        return Promise.reject(`${fieldName} must be of ${exactLength} digits`);
      }
      return Promise.resolve();
    },
  };
};

export const validateNPIPattern = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (npiRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("*NPI must contain digits only!");
    },
  };
};

export const validateMaxValue = (fieldName: string, maxValue: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (Number(value) > maxValue) {
        return Promise.reject(`*Invalid ${fieldName}`);
      }
      return Promise.resolve();
    },
  };
};

export const validateDeaPattern = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (!value) {
        return Promise.resolve();
      }
      if (deaRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        "DEA number must begin with 2 letters followed by 7 digits."
      );
    },
  };
};

export const passwordPatternValidation = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (passwordRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        "Password should be 1 digit, 1special, 1 large and 8 character long!"
      );
    },
  };
};

export const confirmPasswordValidation = (
  getFieldValue: (name: NamePath) => StoreValue
) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      const password = getFieldValue("signupPassword");
      if (!password) {
        return Promise.reject("*Please Enter Password!");
      }
      if (!value || getFieldValue("signupPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The new password that you entered do not match!");
    },
  };
};

export const policyCheckboxValidation = (tabKey: number) => {
  return {
    validator: (_: RuleObject, checked: any) => {
      if (tabKey === 2 && !checked) {
        return Promise.reject(new Error("*You must agree HIPAA Agreement"));
      }
      return Promise.resolve();
    },
  };
};

export const phoneNumberLength = (countryData: CountryData | undefined) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      console.log({
        value,
        formatLength: countryData?.format.split(".").length,
        formatSplit: countryData?.format.split("."),
        countryData,
        valueLength: value.length,
      });
      if (
        countryData &&
        countryData?.format.split(".").length - 1 !== value.length
      ) {
        return Promise.reject(new Error("*Invalid Phone Number!"));
      }
      return Promise.resolve();
    },
  };
};

export const useAsyncValidate = () => {
  const { makeApiRequest } = useMakeApiRequest();

  const validateDeaNumberFormat = (form: FormInstance) => {
    return async (_: RuleObject, value: StoreValue) => {
      if (!value) {
        return Promise.resolve();
      }

      if (value.length !== 9) {
        return Promise.resolve(); // Skip validation if the value isn't 9 characters
      }

      try {
        const lastNameValue = form?.getFieldValue("lastName");
        const result = await makeApiRequest(
          "/auth/validate-dea",
          "POST",
          {},
          {
            dea_number: value,
            last_name: lastNameValue,
          },
          "application/json",
          true
        );

        if (result?.status === 200) {
          return Promise.resolve(result?.message);
        } else if (result?.error) {
          return Promise.reject(new Error(result.error));
        }
      } catch (error: any) {
        return Promise.reject(error?.response?.data?.error);
      }
    };
  };

  return {
    validateDeaNumberFormat,
  };
};
