// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthState } from "../../types/global.types";

const initialState: IAuthState = {
  authUser: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthCredentials: (
      state,
      action: PayloadAction<{
        email: string;
        password: string;
        phoneNumber?: string;
        rememberMe?: boolean;
      }>
    ) => {
      state.authUser = { ...state.authUser, ...action.payload };
    },
    setAuthTokens: (
      state,
      action: PayloadAction<{
        email: string;
        accessToken: string;
        idToken: string;
        refreshToken: string;
        expiresIn: number;
        tokenType: string;
        rememberMe?: boolean;
      }>
    ) => {
      state.authUser = { ...state.authUser, ...action.payload };
    },
    logout: (state) => {
      if (state.authUser?.rememberMe) {
        state.authUser = {
          email: state.authUser.email,
          password: state.authUser.password,
          rememberMe: state.authUser.rememberMe,
        };
      } else {
        state.authUser = null;
      }
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
