import './sidenav.scss';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  QuestionCircleOutlined, LogoutOutlined,
  TagOutlined, MobileOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/useAuth';
const { SubMenu } = Menu;
const { Sider } = Layout;

const SideNav = () => {
  const { logout } = useAuth();
  return (
    <Sider
      width={250}
      style={{ backgroundColor: '#c5b5f2' }}
      className="site-layout-background"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100vh', borderRight: 0 }}
      >
        <Menu.Item key="1" icon={<UserOutlined />}>
          <Link to="/advisory-board-portal-new">Account Settings</Link>
        </Menu.Item>
        <SubMenu key="sub2" icon={<TagOutlined />} title="Products">
          <Menu.Item key="5" icon={<PaperClipOutlined />}>
            <Link to="/advisory-board-new">Advisory Board</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<MobileOutlined />}>
            <Link to="/ePrescribe">ePrescribing App</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          key="9"
          icon={<QuestionCircleOutlined />}
          onClick={() =>
            (window.location.href = ' mailto:support@docupdate.io')
          }
        >
          Support
        </Menu.Item>
        {/* <Menu.Item key="10" icon={<SettingOutlined/>}>Settings</Menu.Item> */}
        <Menu.Item key="10" onClick={logout} icon={<LogoutOutlined />}>
          Sign out
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default SideNav;