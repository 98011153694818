// @flow
import React from 'react';
import { Input } from 'antd';
import './label-text-box.module.scss';

const { TextArea } = Input;

type Props = {
    className?: string,
    multiLine?: boolean,
    maskInput?: boolean,
    rows?: number,
    defaultValue?: string,
    value?: string,
    label?: string,
    placeholder?: string,
    note?: React.ReactNode,
    onChange: (val?: any) => void,
    inputStyle?: React.CSSProperties,
    style?: React.CSSProperties,
    error?: boolean,
    mask?: string,
    errorDescription?: string,
    maxLength?: number,
    isReadonly?: boolean
}

export default function LabelTextBox({
    className = '', style, multiLine = false, maskInput = false, rows, defaultValue, value, label, placeholder, note, onChange, isReadonly,
    inputStyle, error, mask, errorDescription, maxLength
}: Props) {
    return (
        <div className={`text-box ${className}`} style={style}>
            {
                label !== undefined
                    ? <p className='text-box-label'>{label}</p>
                    : <></>
            }
            {
                multiLine
                    ? <TextArea
                        rows={rows}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        value={value || undefined}
                        onChange={onChange} />
                    : <Input
                        readOnly={isReadonly}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        value={value || undefined}
                        onInput={onChange}
                        maxLength={maxLength}
                        status={error ? 'error' : ''}
                        style={{ width: '100%', ...inputStyle, backgroundColor: `${isReadonly ? '#b0aaaa' : '#ffffff'}`, padding: 20 }} />
            }
            <span style={{ color: 'red', fontSize: '10px' }}>{error && (value !== '' ? `${(errorDescription !== '' ? errorDescription : `${label} is invalid.`)}` : `${label} is a required field.`)}</span>
            {note}
        </div>
    );
}
