import { Form as AntdForm } from "antd";

export const useForm = (initialValues?: Object) => {
  const [form] = AntdForm.useForm();

  const setFieldValue = (field: string, value: string) => {
    form.setFieldsValue({ [field]: value });
  };

  const resetForm = (initalValues?: Object) => {
    if (initalValues) {
      form.setFieldsValue(initalValues);
    } else {
      form.resetFields();
    }
  };

  return {
    form,
    setFieldValue,
    resetForm,
  };
};
