export const config = {
    apiUrl: process.env.REACT_APP_API_HOST,
    clientCredentialInternal: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL,
    secretKey: process.env.REACT_APP_API_SECRET_KEY,
    clientCredentialMobile: process.env.REACT_APP_CLIENT_CREDENTIALS_MOBILE,
    epresApiUrl: process.env.REACT_APP_API_EPRESCRIBING_HOST,
    epresSecretKey: process.env.REACT_APP_EPRESCRIBING_SECRET_KEY
};

export const grantType = {
    CLIENT_CREDENTIALS: 'na'
};

export const storageKey = {
    accessToken: 'oldst_accesstoken',
    sidebarState: 'sidebarState'
};

export const genderStatus = [{ id: 1, name: 'Male' }, { id: 2, name: 'Female' }];