import { Button, Modal, Form, message, Row, Col } from 'antd';
import './verify-otp.modules.css';
import { useAuth } from '../../utils/hooks/useAuth';
import { IVerifyOTPProps } from '../../types/global.types';
import { useForm } from '../../utils/hooks/useForm';
import FloatInput from '../../components/FloatInput/floatInput';

const VerifyOTP = ({ open, userName, password, phoneNumber, onClose }: IVerifyOTPProps) => {
  const { form } = useForm();
  const { handleVerifyOTP, resendPhoneOtp, isLoading } = useAuth();

  const onFinish = async (values: { otp: string }) => {
    try {
      await handleVerifyOTP({ username: userName, password, otp: values.otp });
      if (onClose) {
        onClose();
      }
    } catch (error) {
      message.error(`${error}`)
    }
  }

  return (
    <Modal
      title="Phone Number Verification"
      footer={null}
      className='text-center'
      open={open}
    >
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={[12, 12]} className='w-full justify-center items-center'>
          <Col span={24}>
            <Form.Item
              name="otp"
              rules={[
                { required: true, message: 'Please enter the OTP.' },
                { pattern: /^\d{6}$/, message: 'OTP must be a 6-digit number.' },
              ]}
            >
              <FloatInput
                type='number'
                placeholder="*Enter 6-digit OTP"
                maxLength={6}
                controls={false}
                onPressEnter={() => form.submit()}
              />
            </Form.Item>
          </Col>
          <div className='flex flex-col-reverse justify-center items-center mobile-lg:flex-row'>
            <Button
              type="link"
              className='font-urbanist text-[#7e5ae2] font-semibold'
              onClick={() => resendPhoneOtp(phoneNumber, userName)}
              block
            >
              Resend OTP
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="mb-2 font-urbanist font-semibold bg-[#7e5ae2]"
            >
              Verify Phone Number
            </Button>
          </div>
        </Row>
      </Form>
    </Modal>
  );
};

export default VerifyOTP;
