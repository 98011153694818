import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import AdvisoryPortal from '../Pages/AdvisoryBoardPortal/AdvisoryBoardPortal';
import DNC from '../Pages/dnc/dnc';
import AdvisoryBoard from '../Pages/AdvisoryBoard/AdvisoryBoard';
import NotFound from '../Pages/404_Not_Found/404';
import EPrescirbe from '../Pages/EPrescribe/EPrescribe';
import { useEffect } from 'react';
import Signup from '../Pages/Signup/signup';
import SignIn from '../Pages/Signin/signin';
import { useAuth } from '../utils/hooks/useAuth';
import ForgetPassword from '../Pages/ForgotPassword/forgetPassword';
import VerifyOTP from '../Pages/VerifyOTP/verifyOTP';

interface RoutesListProps {
  setBackgroundImage: (background: any) => void;
}

export const authRoutes = ['/verify-otp', '/signup-new', '/login-new', '/forgot-password']



export const RoutesList: React.FC<RoutesListProps> = ({
  setBackgroundImage,
}) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (authRoutes.includes(location.pathname.toLowerCase())) {
      setBackgroundImage({
        minHeight: "100vh",
        backgroundColor: "red", // for browser, who doesn't support gradient
        backgroundImage: "linear-gradient(135.79deg, rgba(241, 253, 255, 0.9) 5.04%, rgba(78, 234, 255, 0.9) 138.58%)"
      });
      return;
    }
    setBackgroundImage({ background: 'white' });
  }, [location.pathname, setBackgroundImage]);
  return (
    <Routes key={location.pathname} location={location}>
      <Route
        path="/"
        key="/"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <Navigate to='/signup-new' />
          )
        }
      />
      <Route
        path="/advisory-board-portal-new"
        element={
          isAuthenticated ? <AdvisoryPortal /> : <Navigate to='/signup-new' />
        }
      />
      <Route
        path='/signup-new'
        key='/signup-new'
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <Signup />
          )
        }
      />
      <Route path="/terms-of-service-new" element={<ExternalRedirect to="https://www.docupdate.io/tos" />} />
      <Route path="/privacy-policy-new" element={<ExternalRedirect to="https://www.docupdate.io/privacy-policy" />} />
      <Route path="/dnc-policy-new" element={<DNC />} />
      <Route
        path="/login-new"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <SignIn />
          )
        }
      />
      <Route
        path="/forgot-password"
        element={
          isAuthenticated ? (
            <Navigate to="/advisory-board-portal-new" />
          ) : (
            <ForgetPassword />
          )
        }
      />
      <Route
        path="/advisory-board-new"
        element={
          isAuthenticated ? <AdvisoryBoard /> : <Navigate to="/signup-new" />
        }
      />
      <Route path="/verify-otp" element={location.state?.email ? <VerifyOTP /> : <Navigate to={"/login-new"} />} />
      <Route path="/ePrescribe" element={<EPrescirbe />} />
      <Route path="/not-found" key="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

function useExternalRedirect(url: string) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
}

const ExternalRedirect = ({ to }: { to: string }) => {
  useExternalRedirect(to);
  return null;
};