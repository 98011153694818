import { useState } from 'react';
import SideNav from './components/SideNav/sideNav';
import { Layout } from 'antd';
import AppHeader from './components/AdvisoryHeader/AdvisoryHeader';
import { RoutesList } from './components/routes';
import { ScrollToTop } from './components/ScrollToTop';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAuth } from './utils/hooks/useAuth';

const App = () => {
  const { isAuthenticated } = useAuth();
  const [background, setBackgroundImage] = useState<any>({ background: 'white' });

  return (
    <Layout style={{ maxHeight: '100vh', overflowY: 'hidden' }}>
      {isAuthenticated && <AppHeader />}
      <Layout style={{ background: 'white' }}>
        {isAuthenticated && window.location.pathname === '/' && <SideNav />}
        <Layout
          className="inner-layout"
          style={{
            padding: '0 0',
            overflowX: 'hidden',
            height: 'calc(100vh - 64px)',
            position: 'relative',
            ...background,
          }}
        >
          <Scrollbars>
            <ScrollToTop />
            <RoutesList setBackgroundImage={setBackgroundImage} />
          </Scrollbars>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;